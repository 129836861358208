import {
    Box,
    Button,
    ListItemText,
    Stack,
    SvgIcon,
    Tab,
    Tabs,
} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {
    GROUPED_SCHOOL_ORDERS_ENDPOINT,
    ORDER_DELIVERIES_ENDPOINT,
    ORDER_PAYMENTS_ENDPOINT,
    SINGLE_ORDER_ENDPOINT,
} from "../../../utils/constants";
import {makeNetworkCall} from "../../../utils/fetchData";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import {PaymentTable} from "../../../sections/payments/payments-table";
import {DeliveriesTable} from "../../../sections/deliveries/deliveries-table";
import AddDelivery from "../../../sections/deliveries/add-deliveries";
import AddPayment from "../../../sections/payments/add-payments";
import ProgressBars from "../../../utils/loading";
import {DateTimeFormateTimeStamp} from "../../../utils/dateTime-fomater";
import {formatDisplayNumber} from "../../../utils/number-formter";
import {SeverityPill} from "../../../components/severity-pill";
import BasePageConfigs from "../../page-configs";
import {printLpoReceipt} from "../../../components/printLpoReceipt";

const usePurchaseOrder = (page, rowsPerPage, id) => {
    const [paymentsData, setPaymentsData] = useState([]);
    const [singleOrder, setSingleOrder] = useState({});
    const [deliveriesData, setDeliveriesData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [paymentsTotals, setPaymentTotals] = useState({});
    const [deliveryTotals, setDeliveriesTotals] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            await makeNetworkCall(
                SINGLE_ORDER_ENDPOINT,
                {order_id: id},
                setSingleOrder,
                null,
                setIsLoading,
                {}
            );

            await makeNetworkCall(
                ORDER_PAYMENTS_ENDPOINT,
                {offset: page * rowsPerPage, per_page: rowsPerPage, order_id: id},
                setPaymentsData,
                setPaymentTotals,
                setIsLoading,
                {}
            );

            await makeNetworkCall(
                ORDER_DELIVERIES_ENDPOINT,
                {offset: page * rowsPerPage, per_page: rowsPerPage, order_id: id},
                setDeliveriesData,
                setDeliveriesTotals,
                setIsLoading,
                {}
            );

            setIsLoading(false);
        };

        fetchData();
    }, [page, rowsPerPage, id]);

    return {
        singleOrder,
        paymentsData,
        paymentsTotals,
        deliveryTotals,
        isLoading,
        deliveriesData,
        setIsLoading,
    };
};

const PurchaseOrder = () => {
    const {id} = useParams();
    const [value, setValue] = useState(0);
    const [orderProducts, setOrderProducts] = useState([]);
    const {page, rowsPerPage, handleRowsPerPageChange, handlePageChange} =
        BasePageConfigs();
    const {
        singleOrder,
        paymentsData: purchaseOrdersPayments,
        paymentsTotals,
        deliveryTotals,
        isLoading,
        deliveriesData: purchaseOrdersDeliveries,
        setIsLoading,
    } = usePurchaseOrder(page, rowsPerPage, id);

    const order = singleOrder ? singleOrder[0] : [];
    const company_name = order?.order?.supplier?.company_name;
    const company_address = order?.order?.supplier?.physical_address || "";
    const componentRef = useRef();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // Update the payload for useLoadData only when order_number is available
    useEffect(() => {
        const postData = {
            offset: page * rowsPerPage,
            per_page: rowsPerPage,
            order_number: order?.order?.order_number,
        };
        makeNetworkCall(
            GROUPED_SCHOOL_ORDERS_ENDPOINT,
            postData,
            setOrderProducts,
            null,
            setIsLoading,
            {}
        );
    }, [order]);


    const purchaseOrderPrintData = () => {
        if (orderProducts) {
            return {
                data: orderProducts.map((data) => ({
                    item_name: data.order.product.item_name,
                    quantity: data.order.quantity,
                    unitOfMeasurement: data.order.product.unit_of_measurement,
                    itemPrice: parseFloat(data.order.order_amount),
                })),
                totalPrice: orderProducts.reduce(
                    (sum, data) => sum + parseFloat(data.order.order_amount),
                    0
                ),
                company_name: company_name,
                company_address: company_address,
                order_number: orderProducts[0]?.order?.order_number,
                requisition_number: orderProducts[0]?.order?.order_number,
                createdDate: order?.order?.date,
            };
        }
        return null;
    };

    const deliveryPrintData = () => {
        if (purchaseOrdersDeliveries.length > 0) {
            return {
                title: "Deliveries Invoice",
                data: purchaseOrdersDeliveries.map((data) => ({
                    item_name: data.delivery.purchase_order.product.item_name,
                    quantity: data.total_delivered,
                    unitOfMeasurement:
                    data.delivery.purchase_order.product.unit_of_measurement,
                    itemPrice: parseFloat(data.delivery.invoice_amount),
                })),
                totalPrice: purchaseOrdersDeliveries.reduce(
                    (sum, data) => sum + parseFloat(data.delivery.invoice_amount),
                    0
                ),
                company_name: company_name,
                order_number:
                    purchaseOrdersDeliveries[0]?.delivery.purchase_order.order_number ||
                    "",
                requisition_number:
                    purchaseOrdersDeliveries[0]?.delivery.purchase_order
                        .requisition_number || "",
                delivery_date: purchaseOrdersDeliveries[0]
                    ? DateTimeFormateTimeStamp(
                        purchaseOrdersDeliveries[0].delivery.timestamp
                    )
                    : "N/A",
                company_address: company_address,
            };
        }
        return {
            title: "Purchase Order Report",
            data: [],
            totalPrice: 0,
            company_name: company_name,
            order_number: "",
            requisition_number: "",
            delivery_date: "N/A",
        };
    };

    const paymentsPrintData = () => {
        if (purchaseOrdersPayments.length > 0) {
            return {
                title: "Payments Voucher",
                data: purchaseOrdersPayments.map((data) => ({
                    item_name: data.payment.purchase_order.product.item_name,
                    quantity: data.payment.purchase_order.quantity,
                    unitOfMeasurement:
                    data.payment.purchase_order.product.unit_of_measurement,
                    itemPrice: parseFloat(data.payment.amount),
                })),
                totalPrice: purchaseOrdersPayments.reduce(
                    (sum, data) => sum + parseFloat(data.payment.amount),
                    0
                ),
                company_name: company_name,
                order_number:
                    purchaseOrdersPayments[0]?.payment.purchase_order.order_number || "",
                requisition_number:
                    purchaseOrdersPayments[0]?.payment.purchase_order
                        .requisition_number || "",
            };
        }
        return {
            title: "Purchase Order Report",
            data: [],
            totalPrice: 0,
            company_name: company_name,
            order_number: "",
            requisition_number: "",
            delivery_date: "N/A",
        };
    };
    const handlePrint = (printDataFunction, type) => {
        printLpoReceipt(printDataFunction(), type);
    };

    return (
        <>
            {isLoading || order === undefined ? (
                <ProgressBars/>
            ) : (
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        py: 2,
                    }}
                >
                    <Stack spacing={3} sx>
                        <Stack direction="row" alignItems="center" spacing={0}>
                            <div style={{marginLeft: "32px"}}>
                                <ListItemText
                                    primary={`${order?.order?.supplier?.company_name}`}
                                    primaryTypographyProps={{variant: "subtitle1"}}
                                    secondary={
                                        <React.Fragment>
                                            {`LPO No. ${order?.order?.order_number}, `}
                                            <SeverityPill color="success">
                                                KSH. {formatDisplayNumber(order?.order_amount)}
                                            </SeverityPill>
                                        </React.Fragment>
                                    }
                                    secondaryTypographyProps={{variant: "caption"}}
                                />
                            </div>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between" spacing={0}>
                            <Box alignItems="center" sx={{display: "flex"}}>
                                <Button
                                    onClick={() =>
                                        handlePrint(purchaseOrderPrintData, "LOCAL PURCHASE ORDER")
                                    }
                                    color="secondary"
                                    startIcon={
                                        <SvgIcon fontSize="small">
                                            <LocalPrintshopIcon/>
                                        </SvgIcon>
                                    }
                                    disabled={!orderProducts}
                                >
                                    PRINT PURCHASE ORDER
                                </Button>
                                {/* <Button
                  onClick={() => handlePrint(deliveryPrintData, "delivery")}
                  color="secondary"
                  startIcon={
                    <SvgIcon fontSize="small">
                      <LocalPrintshopIcon />
                    </SvgIcon>
                  }
                >
                  PRINT DELIVERY
                </Button> */}
                                {/* <Button
                  onClick={() => handlePrint(paymentsPrintData(), "payment")}
                  color="secondary"
                  startIcon={
                    <SvgIcon fontSize="small">
                      <LocalPrintshopIcon />
                    </SvgIcon>
                  }
                >
                  PRINT PAYMENTS
                </Button> */}
                            </Box>
                            <Box alignItems="center" sx={{display: "flex"}}>
                                <div>
                                    <AddDelivery purchaseOrderData={order} type={"inventory"}/>
                                </div>
                                <div style={{marginLeft: "10px"}}>
                                    <AddPayment
                                        page={page}
                                        rowsPerPage={rowsPerPage}
                                        purchaseOrderData={order}
                                    />
                                </div>
                            </Box>
                        </Stack>

                        <Box sx={{width: "100%", bgcolor: "background.paper"}}>
                            <Tabs value={value} onChange={handleChange} centered>
                                <Tab label="Deliveries"/>
                                <Tab label="Payments"/>
                            </Tabs>
                        </Box>
                        <Box sx={{padding: "0 20px"}}>
                            {value === 0 && (
                                <DeliveriesTable
                                    totals={deliveryTotals}
                                    items={purchaseOrdersDeliveries}
                                    onPageChange={handlePageChange}
                                    onRowsPerPageChange={handleRowsPerPageChange}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    isLoading={isLoading}
                                    componentRef={componentRef}
                                />
                            )}
                            {value === 1 && (
                                <PaymentTable
                                    totals={paymentsTotals}
                                    items={purchaseOrdersPayments}
                                    onPageChange={handlePageChange}
                                    onRowsPerPageChange={handleRowsPerPageChange}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    isLoading={isLoading}
                                    componentRef={componentRef}
                                />
                            )}
                        </Box>
                    </Stack>
                </Box>
            )}
        </>
    );
};

export default PurchaseOrder;
