import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import {
  Box,
  Card,
  CardHeader,
  Container,
  Divider,
  FormControl,
  Grid,
  List,
  ListItem,
  OutlinedInput,
  TextField,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { CreateItem } from "../../utils/fetchData";
import * as endpoints from "../../utils/constants";
import { toast } from "react-toastify";
import { useStaff } from "../../pages/staffMembers/staff";
import ProductSelection from "../products/product-configs";
import ProductsSelection from "../products/products-configs";
import { useFetchData } from "../../services/featching-data-service";
import { DEFAULT_PER_PAGE } from "../../utils/constants";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ReceiveAllocation({ mode, data, page }) {
  const [open, setOpen] = useState(false);
  const [formValues, setFormValues] = useState({
    staff_id: data?.allocation?.staff?.id || "",
    notes: "",
    id: "",
  });
  const [allocations, setAllocations] = useState([
    {
      quantity:
        data?.allocation?.quantity * -1 >= 0
          ? data?.allocation?.quantity * -1
          : "",
      product_id: data?.allocation?.product?.id || "",
    },
  ]);

  const [unitOfMeasurements, setUnitOfMeasurements] = useState("");
  const { data: staffs } = useFetchData(
    page,
    DEFAULT_PER_PAGE,
    endpoints.SCHOOL_STAFF_ENDPOINT,
    null,
    null
  );
  const [selectedStaff, setSelectedStaff] = useState(
    data?.allocation?.staff || null
  );

  const handleAddAllocations = () => {
    setAllocations([...allocations, { quantity: "", product_id: "" }]);
  };

  const handleRemoveAllocations = (index) => {
    const updatedAllocations = allocations.filter((_, i) => i !== index);
    setAllocations(updatedAllocations);
  };

  const handleOrderInputChange = (index, field, value) => {
    const updatedAllocations = [...allocations];
    updatedAllocations[index][field] = value;
    setAllocations(updatedAllocations);
  };
  const handleProductChange = (index, unitOfMeasurement) => {
    const updatedUnitOfMeasurements = [...unitOfMeasurements];
    updatedUnitOfMeasurements[index] = unitOfMeasurement;
    setUnitOfMeasurements(updatedUnitOfMeasurements);
  };

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleStaffSelection = (_, value) => {
    setSelectedStaff(value);
    if (value) {
      setFormValues((prevValues) => ({
        ...prevValues,
        staff_id: value.id,
      }));
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        staff_id: "",
      }));
    }
  };

  const handleProductAllocationSubmit = async () => {
    // Check if required fields are empty
    if (
      !formValues.staff_id
      // allocations.some((a) => !a.product_id || !a.quantity)
    ) {
      toast.error("Please fill in all required fields");
    } else {
      const postData = allocations.reduce(
        (acc, allocation) => {
          Object.keys(allocation).forEach((key) => {
            acc[key] = acc[key]
              ? `${acc[key]}, ${allocation[key]}`
              : allocation[key];
          });
          return acc;
        },
        { ...formValues }
      );

      // If all required fields are filled, call CreateItem function
      CreateItem(postData, endpoints.NEW_STAFF_ISSUANCE_ENDPOINT);
    }
  };

  const filterStaffs = (inputValue) => {
    return staffs.filter(
      (staff) =>
        staff.full_name.toLowerCase().includes(inputValue.toLowerCase()) ||
        staff.id_number.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  useEffect(() => {
    if (mode === "edit" && data) {
      setAllocations([
        {
          quantity:
            data?.allocation?.quantity * -1 >= 0
              ? data?.allocation?.quantity * -1
              : "",
          product_id: data?.allocation?.product?.id || "",
        },
      ]);
      setFormValues({
        staff_id: data?.allocation?.staff?.id || "",
        id: data?.allocation?.id,
        notes: data?.allocation?.notes,
      });
      setSelectedStaff(data?.allocation?.staff);
      handleClickOpen();
    }
  }, [mode, data]);

  return (
    <React.Fragment>
      <Button
        startIcon={<PlusIcon fontSize="small" />}
        variant="contained"
        onClick={handleClickOpen}
        sx={{ marginLeft: "20px" }}
      >
        Return an Item
      </Button>

      <Dialog
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div"
            ></Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>

        <List>
          <Container sx={{ marginTop: "1rem" }}>
            <Card sx={{ p: 2, display: "flex", flexWrap: "wrap" }}>
              <Box sx={{ width: "100%" }}>
                <CardHeader
                  subheader="Fill in all the inputs to be able to Receive an Item from the staff"
                  title="Receive an Item from the staff"
                />
                <Divider />
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <ListItem
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "start",
                    }}
                  >
                    <p>
                      Staff
                      <span style={{ color: "red" }}> *</span>
                    </p>

                    <FormControl fullWidth>
                      <Autocomplete
                        options={staffs ? staffs : []}
                        getOptionLabel={(option) =>
                          `${option.full_name.toUpperCase()} - ${option.department.toUpperCase()}`
                        }
                        value={selectedStaff}
                        onChange={handleStaffSelection}
                        filterOptions={(options, params) => {
                          const filtered = filterStaffs(params.inputValue);
                          return filtered;
                        }}
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" fullWidth />
                        )}
                      />
                    </FormControl>
                  </ListItem>
                </Grid>
                <Grid item xs={12} md={6}>
                  {allocations?.map((allocation, index) => (
                    <Box
                      key={index}
                      sx={{
                        mb: 2,
                        p: 2,
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                      }}
                    >
                      <ListItem>
                        <ProductsSelection
                          index={index}
                          setOrderValues={handleOrderInputChange}
                          setProduct={handleProductChange}
                          productType="Inventories"
                          initialProduct={data?.allocation?.product}
                        />
                      </ListItem>
                      <ListItem
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "start",
                        }}
                      >
                        <p>
                          {`Quantity ${unitOfMeasurements}`}
                          <span style={{ color: "red" }}> *</span>
                        </p>

                        <FormControl fullWidth>
                          <OutlinedInput
                            value={allocation.quantity}
                            fullWidth
                            onChange={(e) =>
                              handleOrderInputChange(
                                index,
                                "quantity",
                                e.target.value.replace(/,/g, "")
                              )
                            }
                          />
                        </FormControl>
                      </ListItem>
                      {allocations?.length > 1 && (
                        <Button
                          color="secondary"
                          onClick={() => handleRemoveAllocations(index)}
                          sx={{ marginTop: "10px" }}
                        >
                          Remove
                        </Button>
                      )}
                    </Box>
                  ))}
                  <Button
                    variant="outlined"
                    onClick={handleAddAllocations}
                    sx={{ marginTop: "10px" }}
                  >
                    Receive another Item
                  </Button>
                </Grid>
              </Grid>
              <ListItem
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "start",
                }}
              >
                <Button
                  onClick={handleProductAllocationSubmit}
                  sx={{ width: "100%", marginRight: "5px" }}
                  variant="contained"
                >
                  Submit
                </Button>
              </ListItem>
            </Card>
          </Container>
        </List>
      </Dialog>
    </React.Fragment>
  );
}
