import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ProgressBars from "../../../utils/loading";
import { useNavigate } from "react-router-dom";
import { DateTimeFormateTimeStamp } from "../../../utils/dateTime-fomater";
import DeletePopup from "../../../components/deletePopup";
import { DELETE_STUDENT_ENDPOINT } from "../../../utils/constants";

export const IssuedItemTabTable = (props) => {
  const {
    count = 0,
    items = [],
    onPageChange = () => {},
    onRowsPerPageChange,
    page = 0,
    rowsPerPage = 0,
    isLoading = false,
    componentRef,
  } = props;

  const [dialogDeleteOpen, setDialogDeleteOpen] = React.useState(false);
  const [selectedEntity, setSelectedEntity] = React.useState({
    id: null,
    name: "",
  });

  const navigate = useNavigate();

  const handleNavigate = (id) => {
    navigate(`/student-allocation/${id}`);
  };

  // State for managing the dropdown menu
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleMenuOpen = (event, product) => {
    setAnchorEl(event.currentTarget);
    setSelectedProduct(product);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    handleMenuClose();
    // Handle edit logic here with selectedProduct
    console.log("Editing:", selectedProduct);
  };

  const handleDeleteClick = (order) => {
    setDialogDeleteOpen(true);
    setSelectedEntity({
      id: selectedProduct.product.id,
      type: `this item, ${selectedProduct.product.item_name} issued to ${selectedProduct.student.student_name}`,
    });
    setAnchorEl(null);
  };

  return (
    <Card>
      <Box ref={componentRef} sx={{ minWidth: 800 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Item</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Student Name</TableCell>
                <TableCell>Student ADM</TableCell>
                <TableCell>Student Class</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Allocated By</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={9}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      minHeight: 200,
                    }}
                  >
                    <ProgressBars />
                  </Box>
                </TableCell>
              </TableRow>
            ) : items.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: 200,
                      }}
                    >
                      <ProgressBars />
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {items.map((item, index) => {
                  const rowNum = page * rowsPerPage + index + 1;

                  return (
                    <TableRow hover key={item.student.student_number}>
                      <TableCell
                        onClick={() =>
                          handleNavigate(item.student.student_number)
                        }
                      >
                        {rowNum}
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          handleNavigate(item.student.student_number)
                        }
                      >
                        {item.product.item_name}
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          handleNavigate(item.student.student_number)
                        }
                      >
                        {Math.abs(item.quantity)}
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          handleNavigate(item.student.student_number)
                        }
                      >
                        {item.student.student_name}
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          handleNavigate(item.student.student_number)
                        }
                      >
                        {item.student.student_number}
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          handleNavigate(item.student.student_number)
                        }
                      >{`${item.student?.form} ${item.student?.stream}`}</TableCell>
                      <TableCell
                        onClick={() =>
                          handleNavigate(item.student.student_number)
                        }
                      >
                        {DateTimeFormateTimeStamp(item.timestamp)}
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          handleNavigate(item.student.student_number)
                        }
                      >
                        {item.allocated_by.full_name}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={(event) => handleMenuOpen(event, item)}
                          size="small"
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                        >
                          {/* <MenuItem onClick={handleEditClick}>Edit</MenuItem> */}
                          <MenuItem onClick={handleDeleteClick}>
                            Delete
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Box>

      <TablePagination
        component="div"
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[25, 50, 100]}
      />
      {dialogDeleteOpen && (
        <DeletePopup
          dialogOpen={dialogDeleteOpen}
          setDialogOpen={setDialogDeleteOpen}
          selectedEntity={selectedEntity}
          urlLink={DELETE_STUDENT_ENDPOINT}
        />
      )}
    </Card>
  );
};

IssuedItemTabTable.propTypes = {
  count: PropTypes.number,
  items: PropTypes.array,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  isLoading: PropTypes.bool,
};
