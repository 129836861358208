import {
    Box,
    Button,
    ListItemText,
    Stack,
    SvgIcon,
    Tab,
    Tabs,
} from "@mui/material";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {
    GROUPED_SCHOOL_ORDERS_ENDPOINT,
    ORDER_DELIVERIES_ENDPOINT,
    ORDER_PAYMENTS_ENDPOINT,
    SINGLE_ORDER_ENDPOINT,
} from "../../../utils/constants";
import {makeNetworkCall} from "../../../utils/fetchData";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import {ExportTableButton} from "../../../utils/export-document";
import {printReport} from "../../../utils/print-documents";
import {PaymentTable} from "../../../sections/payments/payments-table";
import {DeliveriesTable} from "../../../sections/deliveries/deliveries-table";
import AddDelivery from "../../../sections/deliveries/add-deliveries";
import AddPayment from "../../../sections/payments/add-payments";
import ProgressBars from "../../../utils/loading";
import {printLpoReceipt} from "../../../components/printLpoReceipt";

const useServices = (page, rowsPerPage, id, product_id) => {
    const [paymentsData, setPaymentsData] = useState([]);
    const [singleOrder, setSingleOrder] = useState({});
    const [deliveriesData, setDeliveriesData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [paymentsTotals, setPaymentTotals] = useState({});
    const [deliveryTotals, setDeliveriesTotals] = useState({});

    useEffect(() => {
        makeNetworkCall(
            SINGLE_ORDER_ENDPOINT,
            {
                order_id: id,
            },
            setSingleOrder,
            null,
            setIsLoading,
            {}
        );

        makeNetworkCall(
            ORDER_PAYMENTS_ENDPOINT,
            {
                offset: page * rowsPerPage,
                per_page: rowsPerPage,
                order_id: id,
            },
            setPaymentsData,
            setPaymentTotals,
            setIsLoading,
            {}
        );
        makeNetworkCall(
            ORDER_DELIVERIES_ENDPOINT,
            {
                offset: page * rowsPerPage,
                per_page: rowsPerPage,
                order_id: id,
            },
            setDeliveriesData,
            setDeliveriesTotals,
            setIsLoading,
            {}
        );
    }, [page, rowsPerPage, id]);

    return {
        singleOrder,
        paymentsData,
        paymentsTotals,
        deliveryTotals,
        isLoading,
        deliveriesData,
        setIsLoading,
    };
};

const LsoOrder = () => {
    const {id} = useParams();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [value, setValue] = useState(0);
    const {
        singleOrder,
        paymentsData: servicesPayments,
        paymentsTotals,
        deliveryTotals,
        isLoading,
        deliveriesData: servicesDeliveries,
        setIsLoading,
    } = useServices(page, rowsPerPage, id);
    const [orderProducts, setOrderProducts] = useState([]);

    const order = singleOrder[0];
    const company_name = order?.order?.supplier?.company_name;
    const company_address = order?.order?.supplier?.physical_address || "";
    const componentRef = useRef();
    const handlePageChange = useCallback((event, value) => {
        setPage(value);
    }, []);

    const handleRowsPerPageChange = useCallback((event) => {
        setRowsPerPage(event.target.value);
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // Update the payload for useLoadData only when order_number is available
    useEffect(() => {
        const postData = {
            offset: page * rowsPerPage,
            per_page: rowsPerPage,
            order_number: order?.order?.order_number,
        };
        makeNetworkCall(
            GROUPED_SCHOOL_ORDERS_ENDPOINT,
            postData,
            setOrderProducts,
            null,
            setIsLoading,
            {}
        );
    }, [order]);
    console.log("order", order);

    const purchaseOrderPrintData = () => {
        // Ensure both orderProducts and singleOrder are loaded before proceeding
        if (orderProducts.length > 0 && singleOrder.length > 0 && order) {
            return {
                data: orderProducts.map((data) => ({
                    item_name: data.order.product.item_name,
                    quantity: data.order.quantity,
                    unitOfMeasurement: data.order.product.unit_of_measurement,
                    itemPrice: parseFloat(data.order.order_amount),
                })),
                totalPrice: orderProducts.reduce(
                    (sum, data) => sum + parseFloat(data.order.order_amount),
                    0
                ),
                company_name: company_name,
                company_address: company_address,
                order_number: singleOrder[0]?.order?.order_number,
                requisition_number: singleOrder[0]?.order?.order_number,
                createdDate: order?.order?.date,
            };
        }
        return {};
    };

    // Async function to ensure data is fetched before printing
    const handlePrint = async (printDataFunction, type) => {
        setIsLoading(true);

        // Wait until the necessary data is available
        while (orderProducts.length === 0 || singleOrder.length === 0) {
            await new Promise((resolve) => setTimeout(resolve, 100));
        }

        // Once data is ready, trigger print
        const printData = printDataFunction();
        printLpoReceipt(printData, "LOCAL SERVICE ORDER");

        setIsLoading(false); // Stop loading indicator after printing
    };
    return (
        <>
            {isLoading || order === undefined ? (
                <ProgressBars/>
            ) : (
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        py: 2,
                    }}
                >
                    <Stack spacing={3} sx>
                        <Stack direction="row" justifyContent="space-between" spacing={0}>
                            <Stack direction="row" alignItems="center" spacing={0}>
                                <div style={{marginLeft: "32px"}}>
                                    <ListItemText
                                        primary={order?.order?.supplier?.company_name}
                                        primaryTypographyProps={{variant: "subtitle1"}}
                                        secondary={`${order?.order?.order_number}, KSH. ${order?.order?.order_amount}`}
                                        secondaryTypographyProps={{variant: "caption"}}
                                    />
                                </div>
                                <Stack alignItems="center" direction="row" spacing={0}>
                                    <Button
                                        onClick={() =>
                                            handlePrint(purchaseOrderPrintData, "LOCAL SERVICE ORDER")
                                        }
                                        color="secondary"
                                        startIcon={
                                            <SvgIcon fontSize="small">
                                                <LocalPrintshopIcon/>
                                            </SvgIcon>
                                        }
                                    >
                                        Print
                                    </Button>
                                    <ExportTableButton
                                        componentRef={componentRef}
                                        pageTittle="LSO Service"
                                    />
                                </Stack>
                            </Stack>
                            <Box sx={{display: "flex"}}>
                                <div>
                                    <AddDelivery purchaseOrderData={order} type={"lso"}/>
                                </div>
                                <div style={{marginLeft: "10px"}}>
                                    <AddPayment
                                        page={page}
                                        rowsPerPage={rowsPerPage}
                                        purchaseOrderData={order}
                                    />
                                </div>
                            </Box>
                        </Stack>

                        <Box sx={{width: "100%", bgcolor: "background.paper"}}>
                            <Tabs value={value} onChange={handleChange} centered>
                                <Tab label="Deliveries"/>
                                <Tab label="Payments"/>
                            </Tabs>
                        </Box>
                        <Box sx={{padding: "0 20px"}}>
                            {value === 0 && (
                                <DeliveriesTable
                                    totals={deliveryTotals}
                                    items={servicesDeliveries}
                                    onPageChange={handlePageChange}
                                    onRowsPerPageChange={handleRowsPerPageChange}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    isLoading={isLoading}
                                    componentRef={componentRef}
                                />
                            )}
                            {value === 1 && (
                                <PaymentTable
                                    totals={paymentsTotals}
                                    items={servicesPayments}
                                    onPageChange={handlePageChange}
                                    onRowsPerPageChange={handleRowsPerPageChange}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    isLoading={isLoading}
                                    componentRef={componentRef}
                                />
                            )}
                        </Box>
                    </Stack>
                </Box>
            )}
        </>
    );
};

export default LsoOrder;
