import React from "react";
import PropTypes from "prop-types";
import {
    Box,
    Card,
    Grid,
    IconButton,
    ListItemText,
    Menu,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from "@mui/material";
import {SeverityPill} from "../../components/severity-pill";
import ProgressBars from "../../utils/loading";
import {formatDisplayNumber} from "../../utils/number-formter";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import {DateTimeFormate} from "../../utils/dateTime-fomater";
import ImageViewer from "../../components/image-popup";
import AddPurchaseOrders from "./add-purchaseOrders";
import PurchaseOrderItems from "./purchaseOrder_items";
import {useNavigate} from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert"; // Icon for dropdown menu
import {DELETE_ORDER_ENDPOINT} from "../../utils/constants";
import DeletePopup from "../../components/deletePopup";

export const PurchaseOrdersTable = (props) => {
    const {
        totals = {},
        items = [],
        onPageChange = () => {
        },
        onRowsPerPageChange,
        page = 0,
        rowsPerPage = 0,
        selected = [],
        isLoading = false,
        componentRef,
    } = props;
    const navigate = useNavigate();
    const [openEditPurchaseOrder, setOpenEditPurchaseOrder] = React.useState({
        open: false,
        data: null,
    });

    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [selectedOrder, setSelectedOrder] = React.useState(null);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedOrderForMenu, setSelectedOrderForMenu] = React.useState(null);
    const [dialogDeleteOpen, setDialogDeleteOpen] = React.useState(false);
    const [selectedEntity, setSelectedEntity] = React.useState({
        id: null,
        name: "",
    });

    const handleTableRowClick = (order) => {
        setSelectedOrder(order);
        setDialogOpen(true);
    };

    const handleEditClick = (event, order) => {
        event.stopPropagation();
        setOpenEditPurchaseOrder({
            open: true,
            data: order,
        });
    };

    const handleTableNavigateClick = (order) => {
        const {id} = order.order;
        navigate(`/purchase-order/${id}`);
    };

    const handleMenuClick = (event, order) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setSelectedOrderForMenu(order);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedOrderForMenu(null);
    };

    const handleDeleteClick = (order) => {
        console.log(order);

        setDialogDeleteOpen(true);
        setSelectedEntity({
            id: order.order.id,
            type: `this purchase order of LPO/LSO. ${order.order.order_number}`,
        });
        setAnchorEl(null);
    };

    return (
        <Card>
            <Box ref={componentRef} sx={{minWidth: 800}}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>LPO/LSO NO.</TableCell>
                                <TableCell>DELIVERY</TableCell>
                                <TableCell>PAYMENT</TableCell>
                                <TableCell>Items</TableCell>
                                <TableCell>Order Amount</TableCell>
                                <TableCell>Supplier</TableCell>
                                <TableCell>Requesting Dept</TableCell>
                                <TableCell>Order Date</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {isLoading ? (
                                <TableRow>
                                    <TableCell colSpan={12}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                minHeight: 200,
                                            }}
                                        >
                                            <ProgressBars/>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ) : items.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={12}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                minHeight: 200,
                                            }}
                                        >
                                            <CustomNoRowsOverlay/>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                <>
                                    {items.map((order, index) => {
                                        const isSelected = selected.includes(order.order.id);
                                        const rowNum = page * rowsPerPage + index + 1;

                                        let deliverBgColor;
                                        if (order.order.delivery_status === "PENDING") {
                                            deliverBgColor = "error"
                                        } else if (order.order.delivery_status === "PARTIALLY DELIVERED") {
                                            deliverBgColor = "warning"
                                        } else {
                                            deliverBgColor = "success"
                                        }

                                        let paymentBgColor;
                                        if (order.order.payment_status === "PENDING") {
                                            paymentBgColor = "error"
                                        } else if (order.order.payment_status === "PARTIALLY PAID") {
                                            paymentBgColor = "warning"
                                        } else {
                                            paymentBgColor = "success"
                                        }

                                        return (
                                            <TableRow
                                                hover
                                                key={order.order.id}
                                                selected={isSelected}
                                            >
                                                <TableCell
                                                    onClick={() => handleTableNavigateClick(order)}
                                                >
                                                    {rowNum}
                                                </TableCell>
                                                <TableCell sx={{padding: "0px"}}>
                                                    <ListItemText
                                                        primary={
                                                            <React.Fragment>
                                                                <div
                                                                    onClick={() =>
                                                                        handleTableNavigateClick(order)
                                                                    }
                                                                >
                                                                    <span>{order.order.order_number}</span>
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                        primaryTypographyProps={{variant: "subtitle1"}}
                                                        secondary={
                                                            order.order.purchase_order_photo && (
                                                                <ImageViewer
                                                                    imageUrl={order.order.purchase_order_photo}
                                                                    tittle={"LPO photo"}
                                                                />
                                                            )
                                                        }
                                                        secondaryTypographyProps={{variant: "caption"}}
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    onClick={() => handleTableNavigateClick(order)}
                                                >
                                                    <SeverityPill color={deliverBgColor}>
                                                        {order.order.delivery_status}
                                                    </SeverityPill>
                                                </TableCell>
                                                <TableCell
                                                    onClick={() => handleTableNavigateClick(order)}
                                                >
                                                    <SeverityPill color={paymentBgColor}>
                                                        {order.order.payment_status}
                                                    </SeverityPill>
                                                </TableCell>
                                                <TableCell
                                                    sx={{
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        cursor: "pointer",
                                                        textDecoration: "underline",
                                                        "&:hover": {
                                                            color: "primary.dark",
                                                            textDecoration: "underline",
                                                        },
                                                    }}
                                                    onClick={() => handleTableRowClick(order)}
                                                >
                                                    {`${order.order_items} Inventories`}
                                                </TableCell>

                                                <TableCell
                                                    onClick={() => handleTableNavigateClick(order)}
                                                    sx={{padding: "1px"}}
                                                >
                                                    <SeverityPill color="secondary">{`Ksh. ${formatDisplayNumber(
                                                        order.order_amount
                                                    )}`}</SeverityPill>
                                                </TableCell>
                                                <TableCell
                                                    onClick={() => handleTableNavigateClick(order)}
                                                    sx={{padding: "0px"}}
                                                >
                                                    <ListItemText
                                                        primary={`${order.order.supplier.company_name}`}
                                                        primaryTypographyProps={{variant: "subtitle1"}}
                                                        secondary={order.order.supplier.physical_address}
                                                        secondaryTypographyProps={{variant: "caption"}}
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    onClick={() => handleTableNavigateClick(order)}
                                                >
                                                    {order.order.requesting_department}
                                                </TableCell>
                                                <TableCell
                                                    onClick={() => handleTableNavigateClick(order)}
                                                >
                                                    {DateTimeFormate(order.order.date)}
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton
                                                        aria-controls="simple-menu"
                                                        aria-haspopup="true"
                                                        onClick={(event) => handleMenuClick(event, order)}
                                                    >
                                                        <MoreVertIcon/>
                                                    </IconButton>
                                                    <Menu
                                                        anchorEl={anchorEl}
                                                        open={
                                                            Boolean(anchorEl) &&
                                                            selectedOrderForMenu === order
                                                        }
                                                        onClose={handleMenuClose}
                                                    >
                                                        <MenuItem
                                                            onClick={(event) => handleEditClick(event, order)}
                                                        >
                                                            Edit
                                                        </MenuItem>
                                                        <MenuItem onClick={() => handleDeleteClick(order)}>
                                                            Delete
                                                        </MenuItem>
                                                    </Menu>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                style={{marginLeft: 16}}
            >
                <Grid item xs={12} sm={6}>
                    <Typography variant="body2" gutterBottom>
                        Total Order Amount:{" "}
                        <SeverityPill color="warning">
                            {` ksh. ${formatDisplayNumber(totals.total_order_amount)}`}
                        </SeverityPill>
                    </Typography>
                </Grid>

                <TablePagination
                    component="div"
                    count={totals.total}
                    onPageChange={onPageChange}
                    onRowsPerPageChange={onRowsPerPageChange}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[25, 50, 100]}
                />
            </Grid>

            {openEditPurchaseOrder.open && (
                <div style={{display: "none"}}>
                    <AddPurchaseOrders
                        mode="edit"
                        type="lpo"
                        data={openEditPurchaseOrder.data}
                    />
                </div>
            )}

            {selectedOrder && (
                <PurchaseOrderItems
                    dialogOpen={dialogOpen}
                    setDialogOpen={setDialogOpen}
                    selectedOrder={selectedOrder}
                    setSelectedOrder={setSelectedOrder}
                />
            )}
            {dialogDeleteOpen && (
                <DeletePopup
                    dialogOpen={dialogDeleteOpen}
                    setDialogOpen={setDialogDeleteOpen}
                    selectedEntity={selectedEntity}
                    urlLink={DELETE_ORDER_ENDPOINT}
                />
            )}
        </Card>
    );
};

PurchaseOrdersTable.propTypes = {
    items: PropTypes.array.isRequired,
    totals: PropTypes.object.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onRowsPerPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    selected: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    componentRef: PropTypes.any,
};
