import PropTypes from "prop-types";
import {
    Box,
    Button,
    Card,
    Grid,
    ListItemText,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from "@mui/material";
import {SeverityPill} from "../../components/severity-pill";
import ProgressBars from "../../utils/loading";
import {formatDisplayNumber} from "../../utils/number-formter";
import React from "react";
import {useNavigate} from "react-router-dom";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import {DateTimeFormate} from "../../utils/dateTime-fomater";
import AddService from "./add-lsoOrder";

export const ServiceTable = (props) => {
    const {
        totals = {},
        items = [],
        onPageChange = () => {
        },
        onRowsPerPageChange,
        page = 0,
        rowsPerPage = 0,
        selected = [],
        isLoading = false,
        componentRef,
    } = props;

    const navigate = useNavigate();

    const handleTableRowClick = (order) => {
        const {
            id,
            quantity,
            product: {item_name: product_name, unit_of_measurement, id: product_id},
            supplier: {company_name},
        } = order.order;

        navigate(
            `/lso-order/${id}/${quantity}/${product_name.replace(
                "/",
                "|"
            )}/${company_name}/${unit_of_measurement}/${product_id}`
        );
    };
    const handleEditClick = (event, order) => {
        event.stopPropagation();
        setOpenEdit({open: true, data: order});
    };

    const [openEdit, setOpenEdit] = React.useState({
        open: false,
        data: null,
    });
    return (
        <Card>
            <Box ref={componentRef} sx={{minWidth: 800}}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>LPO/LSO NO.</TableCell>
                                <TableCell>DELIVERY</TableCell>
                                <TableCell>PAYMENT</TableCell>
                                <TableCell>Item</TableCell>
                                <TableCell>Order Amount</TableCell>
                                <TableCell>Supplier</TableCell>
                                <TableCell>Requesting Dept</TableCell>
                                <TableCell>Order Date</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {isLoading ? (
                                <TableRow>
                                    <TableCell colSpan={12}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                minHeight: 200,
                                            }}
                                        >
                                            <ProgressBars/>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ) : items.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={12}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                minHeight: 200,
                                            }}
                                        >
                                            <CustomNoRowsOverlay/>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                <>
                                    {items.map((order, index) => {
                                        const isSelected = selected.includes(order.order.id);
                                        const rowNum = page * rowsPerPage + index + 1;
                                        let deliverBgColor;
                                        if (order.order.delivery_status === "PENDING") {
                                            deliverBgColor = "error"
                                        } else if (order.order.delivery_status === "PARTIALLY DELIVERED") {
                                            deliverBgColor = "warning"
                                        } else {
                                            deliverBgColor = "success"
                                        }

                                        let paymentBgColor;
                                        if (order.order.payment_status === "PENDING") {
                                            paymentBgColor = "error"
                                        } else if (order.order.payment_status === "PARTIALLY PAID") {
                                            paymentBgColor = "warning"
                                        } else {
                                            paymentBgColor = "success"
                                        }
                                        return (
                                            <TableRow
                                                onClick={() => handleTableRowClick(order)}
                                                hover
                                                key={order.order.id}
                                                selected={isSelected}
                                            >
                                                <TableCell>{rowNum}</TableCell>
                                                <TableCell>{order.order.order_number}</TableCell>
                                                <TableCell>
                                                    <SeverityPill color={deliverBgColor}>
                                                        {order.order.delivery_status}
                                                    </SeverityPill>
                                                </TableCell>
                                                <TableCell>
                                                    <SeverityPill color={paymentBgColor}>
                                                        {order.order.payment_status}
                                                    </SeverityPill>
                                                </TableCell>
                                                <TableCell>{order.order.product.item_name}</TableCell>
                                                <TableCell sx={{padding: "1px"}}>
                                                    <SeverityPill color="secondary">{`Ksh. ${formatDisplayNumber(
                                                        order.order.order_amount
                                                    )}`}</SeverityPill>
                                                </TableCell>
                                                <TableCell sx={{padding: "0px"}}>
                                                    <ListItemText
                                                        primary={`${order.order.supplier.full_name}`}
                                                        primaryTypographyProps={{variant: "subtitle1"}}
                                                        secondary={order.order.supplier.company_name}
                                                        secondaryTypographyProps={{variant: "caption"}}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    {order.order.requesting_department}
                                                </TableCell>
                                                <TableCell>
                                                    {DateTimeFormate(order.order.date)}
                                                </TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        size="small"
                                                        onClick={(event) => handleEditClick(event, order)}
                                                    >
                                                        Edit
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                style={{marginLeft: 16}}
            >
                <Grid item xs={12} sm={6}>
                    <Typography variant="body2" gutterBottom>
                        Total Order Amount:{" "}
                        <SeverityPill color="warning">
                            {` ksh. ${formatDisplayNumber(totals.total_order_amount)}`}
                        </SeverityPill>
                    </Typography>
                </Grid>

                <TablePagination
                    component="div"
                    count={totals.total}
                    onPageChange={onPageChange}
                    onRowsPerPageChange={onRowsPerPageChange}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[25, 50, 100]}
                />
            </Grid>
            {openEdit.open && (
                <div style={{display: "none"}}>
                    <AddService mode="edit" data={openEdit.data}/>
                </div>
            )}
        </Card>
    );
};

ServiceTable.propTypes = {
    totals: PropTypes.object,
    items: PropTypes.array,
    onDeselectAll: PropTypes.func,
    onDeselectOne: PropTypes.func,
    onPageChange: PropTypes.func,
    onRowsPerPageChange: PropTypes.func,
    onSelectAll: PropTypes.func,
    onSelectOne: PropTypes.func,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    selected: PropTypes.array,
    isLoading: PropTypes.bool,
};
